import { useAuth } from 'components/Authentication/Provider'
import { FILECOIN_CURRENCY_NAME } from 'domain/currency/constants'
import { createContext, useContext } from 'react'
import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

interface CurrencyContextProps {
  filecoin: any
  refresh: () => void
}

export const CurrencyContext = createContext({})

export const CurrencyProvider: React.FC = ({ children }) => {
  const { user } = useAuth()
  const shouldFetch = user?.id ? `currency/${FILECOIN_CURRENCY_NAME}` : null

  const { data: filecoin, mutate } = useSWR(shouldFetch, fetcher, {
    revalidateOnMount: true,
  })

  const context = {
    filecoin,
    refresh: mutate,
  }

  return <CurrencyContext.Provider value={context}>{children}</CurrencyContext.Provider>
}

export const useCurrency = () => useContext(CurrencyContext) as CurrencyContextProps
